<template>
    <div class="terms-content">
        <div class="card card-dark">
            <div class="card-header">
                <p>
                    Effective Date: April 2, 2025
                </p>
                <h3 class="color-primary">Terms of Service</h3>
            </div>
            <div class="card-body">
            </div>
        </div>

        <div class="card card-dark">
            <div class="card-header">
                <h3 class="color-primary">
                    1. Acceptance of Terms
                </h3>
            </div>
            <div class="card-body">
                <p>
                    By accessing or using audio.love ("the Site"), you agree to be bound by these Terms of Service. If you do not agree, you must discontinue use immediately.
                </p>
            </div>
        </div>

        <div class="card card-dark">
            <div class="card-header">
                <h3 class="color-primary">2. Eligibility</h3>
            </div>
            <div class="card-body">
                <p>
                    You must be at least 18 years old (or the legal age of majority in your jurisdiction) to use this Site. By using the Site, you affirm that you meet this requirement.
                </p>
            </div>
        </div>

        <div class="card card-dark">
            <div class="card-header">
                <h3 class="color-primary">3. User Accounts</h3>
            </div>
            <div class="card-body">
                <ul>
                    <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
                    <li>You agree to provide accurate and up-to-date information.</li>
                    <li>The Site reserves the right to suspend or terminate accounts that violate these Terms.</li>
                </ul>
            </div>
        </div>

        <div class="card card-dark">
            <div class="card-header">
                <h3 class="color-primary">4. Content Guidelines</h3>
            </div>
            <div class="card-body">
                <ul>
                    <li>You may upload, stream, and share erotic audio content, provided it complies with all applicable laws.</li>
                    <li>Content involving non-consensual acts, child exploitation, hate speech, or illegal activity is strictly prohibited.</li>
                    <li>You affirm that you own or have permission to share all uploaded content.</li>
                    <li>The Site reserves the right to remove content that violates these terms.</li>
                </ul>
            </div>
        </div>

        <div class="card card-dark">
            <div class="card-header">
                <h3 class="color-primary">5. Search Engine Functionality</h3>
            </div>
            <div class="card-body">
                <ul>
                    <li>The Site includes a search engine that indexes content from third-party erotic audio hosting sites and provides links to them.</li>
                    <li>The Site does not host or control indexed third-party content and is not responsible for its legality, accuracy, or availability.</li>
                    <li>By using this functionality, you acknowledge that external content is subject to the terms and policies of the respective third-party sites.</li>
                </ul>
            </div>
        </div>

        <div class="card card-dark">
            <div class="card-header">
                <h3 class="color-primary">6. Intellectual Property</h3>
            </div>
            <div class="card-body">
                <ul>
                    <li>You retain ownership of any content you upload but grant the Site a license to host, distribute, and display it.</li>
                    <li>You may not use copyrighted material without permission.</li>
                    <li>The Site does not claim ownership over indexed third-party content and does not assume responsibility for copyright disputes regarding such content.</li>
                </ul>
            </div>
        </div>

        <div class="card card-dark">
            <div class="card-header">
                <h3 class="color-primary">7. Prohibited Activities</h3>
            </div>
            <div class="card-body">
                <ul>
                    <li>Do not use the Site for illegal activities.</li>
                    <li>Do not harass, threaten, or impersonate others.</li>
                    <li>Do not attempt to hack, spam, or disrupt the Site.</li>
                </ul>
            </div>
        </div>        

        <div class="card card-dark">
            <div class="card-header">
                <h3 class="color-primary">8. Disclaimers & Limitations of Liability</h3>
            </div>
            <div class="card-body">
                <ul>
                    <li>The Site is provided "as is," without warranties of any kind.</li>
                    <li>The Site is not responsible for user-generated content.</li>
                    <li>The Site is not liable for any damages resulting from your use of the service.</li>
                    <li>The Site does not guarantee the accuracy, legality, or availability of indexed third-party content and is not responsible for any issues arising from its use.</li>
                </ul>
            </div>
        </div>

        <div class="card card-dark">
            <div class="card-header">
                <h3 class="color-primary">9. Termination</h3>
            </div>
            <div class="card-body">
                <p>The Site reserves the right to terminate accounts or restrict access for any reason, with or without notice.</p>
            </div>
        </div>

        <div class="card card-dark">
            <div class="card-header">
                <h3 class="color-primary">10. Changes to Terms</h3>
            </div>
            <div class="card-body">
                <p>We may update these Terms at any time. Continued use after changes constitutes acceptance of the revised Terms.</p>
            </div>
        </div>

        <div class="card card-dark">
            <div class="card-header">
                <h3 class="color-primary">11. Contact Information</h3>
            </div>
            <div class="card-body">
                <p>For questions regarding these Terms, contact us at audiolove.relations@gmail.com.</p>
            </div>
        </div>
        
    </div>
</template>

<script>
    import { setMetadata } from '../utils.js'

    export default {
        name: 'TermsConditionsPage',
        mounted() {
            setMetadata("Terms and conditions | AudioLove")
        }
    }
</script>

<style scoped>
    .terms-content {
        color: lightgray;
    }

    .card {
        margin-bottom: 1rem;
    }
</style>